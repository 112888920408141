import { render, staticRenderFns } from "./CreateNewTemplateModal.vue?vue&type=template&id=230f1ffc&scoped=true"
import script from "./CreateNewTemplateModal.vue?vue&type=script&lang=js"
export * from "./CreateNewTemplateModal.vue?vue&type=script&lang=js"
import style0 from "./CreateNewTemplateModal.vue?vue&type=style&index=0&id=230f1ffc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230f1ffc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,TextField: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/TextField/TextField.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,DropDownItem: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/DropDown/DropDownItem.vue').default,DropDown: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/DropDown/DropDown.vue').default,Modal: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Modal/Modal.vue').default})
