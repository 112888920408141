
export default {
  props: {
    propertyType: {
      type: String,
      required: true,
    },

    templates: {
      type: Array,
      required: true,
    },
  },

  methods: {
    propertyTypeStringTranslate() {
      if (this.propertyType === "MAIN_PROPERTY") {
        return this.$t("TEMPLATE_BUILDER_MAIN_PROPERTY");
      } else if (this.propertyType == "MAIN_PROPERTY_DIVIDED_IN_CONDOS") {
        return this.$t("TEMPLATE_BUILDER_MAIN_PROPERTY_DIVIDED_IN_CONDOS");
      } else {
        return this.$t("TEMPLATE_BUILDER_CONDO");
      }
    },
    goToTemplateBuilder(item) {
      this.$router.push({
        name: "template-builder-id",
        params: { id: item.id },
      });
    },
  },
};
