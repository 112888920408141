import { render, staticRenderFns } from "./PropertyGroup.vue?vue&type=template&id=0ced9b20&scoped=true"
import script from "./PropertyGroup.vue?vue&type=script&lang=js"
export * from "./PropertyGroup.vue?vue&type=script&lang=js"
import style0 from "./PropertyGroup.vue?vue&type=style&index=0&id=0ced9b20&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ced9b20",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default})
