
import CreateFileDownloadTemplateInput from "~/graphql/Template/CreateTemplate.gql";
import Templates from "~/graphql/Template/Templates.gql";

export default {
  props: {
    propertyTypes: {
      type: Array,
      required: true,
    },

    templates: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modal: false,
      templateName: "",
      templatePropertyType: this.propertyTypes[0],
    };
  },

  computed: {
    isFormValid() {
      return !!this.templateName;
    },
  },

  methods: {
    toggleModal() {
      if (this.modal === false) {
        this.modal = true;
      } else {
        this.modal = false;
      }
    },

    propertyTypeStringTranslate(propertyType) {
      if (propertyType === "MAIN_PROPERTY") {
        return this.$t("TEMPLATE_BUILDER_MAIN_PROPERTY");
      } else if (propertyType === "MAIN_PROPERTY_DIVIDED_IN_CONDOS") {
        return this.$t("TEMPLATE_BUILDER_MAIN_PROPERTY_DIVIDED_IN_CONDOS");
      } else {
        return this.$t("TEMPLATE_BUILDER_CONDO");
      }
    },

    async onClickConfirm() {
      this.$apollo
        .mutate({
          mutation: CreateFileDownloadTemplateInput,
          variables: {
            input: {
              name: this.templateName,
              propertyType: this.templatePropertyType,
              template: [],
            },
          },
          refetchQueries: [{ query: Templates }],
        })
        .then((response) => {
          this.$router.push({ name: "template-builder-id", params: { id: response.data.createFileDownloadTemplate.id } });
        });
      this.toggleModal();
    },
  },
};
