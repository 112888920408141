
import PropertyGroup from "./components/PropertyGroup.vue";
import CreateNewTemplateModal from "./components/CreateNewTemplateModal.vue";
import Templates from "~/graphql/Template/Templates.gql";

export default {
  components: {
    PropertyGroup,
    CreateNewTemplateModal,
  },
  apollo: {
    fileDownloadTemplates: {
      query: Templates,
      variables() {
        return {
          filter: {
            includeStaticTemplates: false,
          },
        };
      },
    },
  },

  data() {
    return {
      error: null,
      PropertyType: {
        Condo: "CONDO",
        MainProperty: "MAIN_PROPERTY",
        MainPropertyDividedInCondos: "MAIN_PROPERTY_DIVIDED_IN_CONDOS",
      },
    };
  },

  computed: {
    MAIN_PROPERTY() {
      if (Array.isArray(this.fileDownloadTemplates)) {
        return this.fileDownloadTemplates.filter((x) => x.propertyType == this.PropertyType.MainProperty);
      }
      return [];
    },
    MAIN_PROPERTY_DIVIDED_IN_CONDOS() {
      if (Array.isArray(this.fileDownloadTemplates)) {
        return this.fileDownloadTemplates.filter((x) => x.propertyType == this.PropertyType.MainPropertyDividedInCondos);
      }
      return [];
    },
    CONDO() {
      if (Array.isArray(this.fileDownloadTemplates)) {
        return this.fileDownloadTemplates.filter((x) => x.propertyType == this.PropertyType.Condo);
      }
      return [];
    },
  },
};
